import React from 'react';
import Img from "gatsby-image";
import { useStaticQuery, graphql, withPrefix, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import Call from '../components/Call';

const Home = (props) => {
  const allImages = props.data.allFile.edges;
  return (
    <Layout bodyClass="page-home">
      <SEO 
      title="Custom Car Custom | Hot Rod Shop | Gizmos"
      description="Welcome to Gizmos Custom Car Shop. We Build Custom: Hot Rods, Pro Touring Cars, Muscle Cars, Land Speed Cars, and Handle Car Restoration"
      />
      <div className="intro pb-4">
        <div className="container">
          {/* Section 1 */}
          <h1>About Gizmos - Custom Car Shop</h1>
          <p>
          Greg Friedrich has been involved in land speed racing, drag racing and road racing for his entire career: the only career he’s ever had. If there’s one thing Greg Friedrich is known for, it’s making cars go fast.
          </p>
        </div>
      </div>

      <div className="container pt-2">
        <Call button />
      </div>
      <div className="container pt-10">
        {/* Section 2 */}
        <h2 className="pb-5">Our Custom Car Build Approach And How You Benefit From A Customer First Philosophy</h2>
        <p className="text-light">
          Greg founded Gizmos custom hot rod shop back in 2003 in a rented garage in Blaine, Minnesota. Quickly outgrowing their first location, Gizmos is now located in their own 4000 square foot shop and office in Cedar, Minnesota.
          </p>
          <p>
          From show stopping custom hot rods and track burning drag cars to salt flat record-breakers and daily drivers; if you want a custom hot rod that’ll leave the competition in the dust and look good doing it – Gizmos is your shop.
          </p>
          <p>
          Gizmos philosophy is simple: To bring customers dreams to fruition by building show-stopping, race-winning, high performance hot rods.
          </p>
          <p>
          Their achievements illustrate the successful implementation of that philosophy with an enviable list of show and race winning automobiles. 
          </p>
          <p>
          Greg Friedrich and the Gizmos crew want you to have the best. Gizmos clients appreciate the way they work with them to come up with what’s best suited for their needs.
          </p>
          <p>
          The customer essentially becomes the “designer”, Gizmos builds your hot rod to meet your needs, with their unique style of quality construction and finishing.
          </p>
          {/* Section 3 */}
          <h2>Not Your Typical Hot Rod Shop Or Custom Builds</h2>
          <p>
          Minnesota is home to one of the most active street rod associations in the country: the Minnesota Street Rod Association. Their annual Back to the Fifties car show, held at the Minnesota State Fairgrounds in Saint Paul, is one of the largest street rod shows in the country.
          </p>
          <p>
          As you might imagine, considering all the hot rod activity in Minnesota, there are a surplus of hot rod shops. There is only one Gizmos.
          </p>
          {/* Section 4 */}
          <h2>Gizmos Specialities</h2>
          <p>As a specialty car builder, Gizmos has a well deserved reputation for combining customized fabrication with the precise detailed construction you’d expect in a turn-key car.</p>
          <p>Their specialties include:</p>
          <ul>
            <li>Hand-built custom specialty cars</li>
            <li>High performance chassis construction</li>
            <li>Suspension engineering and set-up</li>
            <li>Metal fabrication</li>
            <li>Tig welding</li>
            <li>Carbon fiber and fiberglass</li>
            <li>Custom Headers and exhaust</li>
            <li>Complete wiring & plumbing</li>
            <li>Turbo system engineering</li>
          </ul>
          <h2>Custom Work in a Private Shop</h2>
            <p>While most shops brag about their work to everyone and their brother, Gizmos takes a different approach. Privacy.</p>

            <p>The Gizmos shop  is open only by appointment. It is never open to the general public. If you have a car at Gizmos and come to inspect the work, all cars in the shop – except yours – will be covered for privacy.</p>

            <p>Minnesota hot rod enthusiasts bring their cars to Gizmos because they want custom quality work they can’t get anywhere else. Gizmos clients want show-stopping, ass-kicking, speed-demons; and like any proud owner – they want to be the one to reveal their new baby.</p>

            <p>Certainly Gizmos will ask to use images of your completed project on their website – but not until you’ve had the chance to reveal the finished product to the world.</p>
            
            <h2>Gizmos Protects Your Investment</h2>
            <p>Leave your hot rod to the typical shop and you’re leaving it totally unprotected. Most car owners don’t realize; very few shops have insurance to cover the loss of your hot rod. Not Gizmos.</p>

            <p>Like any business, Gizmos building and equipment are insured, but so too is your hot rod. No one wants to ponder the unthinkable and at Gizmos you don’t have to; your investment is completely protected against loss of any kind.</p>

            <p>The interior of the Gizmos shop is protected by a 24/7 security system and monitored by a state-of-the-art surveillance system.</p>
            <h2>Gizmos – Defining Modern Hot Rodding</h2>
            <p>The Gizmos name has become synonymous with high quality hot rods throughout the Midwest; their cars, construction techniques and attention to detail defining modern hot rodding.</p>

            <p>To set up your no-obligation initial build consultation visit their contact page.</p>
          </div>
      {/* <div className="container pt-8 pt-md-10">
        <div className="row justify-content-start">
          <div className="col-12">
            <h2 className="title-3 text-light mb-3">Our Services</h2>
          </div>
            <div className="col-12 col-md-4 mb-1">
              <div className="card service service-teaser">
                <div className="card-content">
                  <h2>
                    <Link to='#'>Link here</Link>
                  </h2>
                  <p>Content here</p>
                </div>
              </div>
            </div>
          <div className="col-12 text-center">
            <Link className="button button-primary mt-2" to="/services">
              View All Services
            </Link>
          </div>
        </div>
      </div> */}
      <div className="container pt-5 pb-5 pt-md-7 pb-md-7">
        <div className="row justify-content-center">
          <div className="col-12">
            <h2 className="text-light mb-4">Products We Use And Trust:</h2>
          </div>
            {allImages.map(edge => (
            <div key={edge.node.id} className="col-6 col-md-3 col-lg-3 mb-2">
              <div className="product">
                  <div className="product-image">
                    <Img
                      fluid={edge.node.childImageSharp.fluid}
                      key={edge.node.id}
                      alt={edge.node.name.replace(/-/g, ' ')}
                    />
                  </div>
                <h2 className="feature-title">{edge.node.title}</h2>
                <div className="feature-content">{edge.node.description}</div>
              </div>
            </div>
          ))}
            </div>
        </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    allFile(
          sort: { fields: name, order: DESC }
          filter: { absolutePath: { regex: "/images/product-logos/" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`;

export default Home;
